import { CheckCircleOutlined } from '@ant-design/icons'
import { Table, Tag } from 'antd'
import { rem } from 'polished'
import { css, styled } from 'styled-components'

const disabled = css`
  opacity: 0.5;
`

const bgColor = {
  vtex: '#FF3366',
  cisp1: '#0059C2',
  anymarket: '#EB6608',
}

export const Container = styled.div<{ $disabled: boolean }>`
  min-width: 387px;
  min-height: 265px;
  border-radius: 6px;
  border: 1px solid ${({ $disabled }) => ($disabled ? '#D9D9D9' : '#80c343')};
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
`

export const TitleContainer = styled.div<{ $disabled: boolean }>`
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;

  h2 {
    font-size: ${rem(14)};
    font-weight: 700;
    ${({ $disabled }) => $disabled && disabled}
  }

  svg {
    color: ${({ theme }) => theme.colors.brand};
    cursor: pointer;
  }
`

export const CategoryTitle = styled.h3<{ $hide?: boolean }>`
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: ${rem(14)};
  font-weight: 400;
  color: #43434399;
  ${({ $hide }) => $hide && 'visibility: hidden;'}
`

export const Row = styled.div`
  display: flex;
`

export const CategoryContainer = styled.div<{ $disabled: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${({ $disabled }) => $disabled && disabled}
`

export const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`

export const ChannelTag = styled(Tag)<{ $checked: boolean; $channel: string }>`
  border-radius: 10px;
  padding-inline: 16px;
  color: #666666;
  text-transform: capitalize;

  ${({ $checked, $channel }) =>
    $checked &&
    css`
      background-color: ${bgColor[$channel as keyof typeof bgColor]};
      color: #fff;
    `}
`
export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`
export const CustomTable = styled(Table)<{ $hide?: boolean }>`
  .ant-table-tbody > tr > td {
    border: none;
  }

  .ant-table-thead > tr > th {
    border-bottom: 0;

    ${({ $hide }) =>
      $hide &&
      css`
        background: #fff;
        &:not(:last-child):not(.ant-table-selection-column):not(
            .ant-table-row-expand-icon-cell
          ):not([colspan])::before {
          width: 0 !important;
        }
      `}
  }
`

export const ChannelInfo = styled.span<{ $disabled: boolean; $color?: string }>`
  font-size: ${rem(16)};
  font-weight: 700;
  ${({ $disabled }) => $disabled && disabled}
  color: ${({ $color }) => $color};
`
