import { Fragment, useCallback, useEffect, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { useQueries } from '@tanstack/react-query'
import { Form, Input, Empty, Select, Skeleton } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { ApiError, toastError } from '@/common'
import { HelpTooltip, LoadingSpinner } from '@/components'
import { bgQueries, Product } from '@/features/BG'
import { categoryQueries, formatCategories } from '@/features/categories'
import { CreateKitFormValues, ItemCard } from '@/features/kits'

type ListingItensFieldProps = {
  data: Product[] | undefined
  isInSelection?: boolean
  isLoading?: boolean
}

interface InformationsItemsProps {
  formValues: CreateKitFormValues
}

export const InformationsItems = ({ formValues }: InformationsItemsProps) => {
  const form = Form.useFormInstance()
  const [selectedProducts, setSelectedProducts] = useState<Product[]>(
    () => formValues.selectedProducts || [],
  )
  const [productSearch, setProductSearch] = useState('')

  const descriptionLength = Form.useWatch('description', form)?.length || 0

  const { products, categories, isCategoriesLoading, isFetchingProducts, error } = useQueries({
    queries: [
      bgQueries.list({
        page: 1,
        logs_per_page: 1000,
        search: productSearch,
        integrated_with_vtex: true,
        is_kit: false,
      }),
      categoryQueries.list({
        page: 1,
        per_page: 1000,
      }),
    ],
    combine: (result) => {
      const [products, categories] = result

      return {
        products: products.data?.products,
        categories: categories.data?.categories,
        isPending: result.some((r) => r.isPending),
        isCategoriesLoading: categories.isPending,
        isFetchingProducts: products.isFetching,
        error: result.find((r) => r.error)?.error,
      }
    },
  })

  const categoryOptions = Object.entries(formatCategories(categories || [])).map(
    ([key, value]) => ({
      label: key,
      options: value.map((category) => ({
        key: category.id,
        label: category.name,
        value: category.id,
      })),
    }),
  )

  const handleProductSelection = useCallback(
    (product: Product, isSelected: boolean) => {
      // If selecting product
      if (isSelected) {
        if (!selectedProducts.some((selectedProduct) => selectedProduct.id === product.id)) {
          const updatedItems = [...selectedProducts, product]

          setSelectedProducts(updatedItems)
          form.setFieldValue('selectedProducts', updatedItems)
        }

        return
      }

      // If unselecting product
      const updatedItems = selectedProducts.filter(
        (selectedProduct) => selectedProduct.id !== product.id,
      )
      setSelectedProducts(updatedItems)
      form.setFieldValue('selectedProducts', updatedItems)
    },
    [form, selectedProducts],
  )

  useEffect(() => {
    if (error) {
      toast.error(
        toastError({
          error: error as ApiError,
          defaultMessage: 'Falha ao carregar dados do produto',
          errorMessages: new Map([
            [
              'invalid datetime format. use the format ‘yyyy/mm/dd hh:mm:ss’',
              'Formato de data e hora inválido. Use o formato "yyyy/mm/dd hh:mm:ss"',
            ],
            ['Not Found', 'Produto(s) não encontrado'],
          ]),
        }),
      )
    }
  }, [error])

  const ListingItensField = ({ data, isInSelection, isLoading }: ListingItensFieldProps) => {
    return (
      <S.ListedItensContainer>
        <S.Label>
          {isInSelection ? (
            <>
              <strong>{productSearch ? 'Resultados' : 'Produtos'}</strong>
              <span> - clique em um produto para adicionar</span>
            </>
          ) : (
            <strong>Adicionados</strong>
          )}
        </S.Label>

        <S.ItensContent>
          {isLoading ? (
            <Skeleton active />
          ) : (data ?? []).length > 0 ? (
            data?.map((product, i) => (
              <Fragment key={i}>
                <ItemCard
                  data={product}
                  isInSelection={isInSelection}
                  isSelected={
                    isInSelection &&
                    selectedProducts.some((selectedProduct) => selectedProduct.id === product.id)
                  }
                  onSelectProduct={handleProductSelection}
                />
              </Fragment>
            ))
          ) : (
            <S.EmptyContent
              description={
                isInSelection ? 'Nenhum produto encontrado' : 'Nenhum produto adicionado'
              }
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </S.ItensContent>
      </S.ListedItensContainer>
    )
  }

  return (
    <S.Card>
      <S.CardContent>
        <S.Container>
          <S.Row className="name-category">
            <Form.Item
              label={<S.Label>Categoria</S.Label>}
              name="category_id"
              rules={[{ required: true, message: 'Informe a categoria do kit.' }]}
            >
              <Select
                labelInValue
                options={categoryOptions}
                loading={isCategoriesLoading}
                disabled={isCategoriesLoading}
                placeholder="Ex: Notebook"
              />
            </Form.Item>

            <Form.Item
              label={<S.Label>Part Number</S.Label>}
              name="ref_id"
              rules={[{ required: true, message: 'Informe o part number do kit.' }]}
            >
              <Input max={26} maxLength={24} placeholder="Ex: NH.XXX.0000" />
            </Form.Item>

            <Form.Item
              label={<S.Label>Nome</S.Label>}
              name="name"
              rules={[{ required: true, message: 'Informe o nome do kit.' }]}
            >
              <Input placeholder="Ex: A515-G2A-79TR" />
            </Form.Item>
          </S.Row>

          <Form.Item
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                <S.Label>Buscar produtos</S.Label>
                <HelpTooltip
                  title={
                    <>
                      <span>Só são selecionaveis os produtos válidos dentro da VTEX.</span>
                      <br />
                      <br />
                      <span>Pressione a tecla `Enter` para buscar</span>
                    </>
                  }
                />
              </div>
            }
            name="searchedItens"
          >
            <S.SearchInput
              suffix={isFetchingProducts ? <LoadingSpinner /> : <SearchOutlined />}
              enterButton
              onSearch={(searchValue) => {
                setProductSearch(searchValue)
              }}
              onChange={(e) => {
                if (e.target.value === '') {
                  setProductSearch('')
                }
              }}
            />
          </Form.Item>

          <S.Column>
            <ListingItensField isInSelection data={products} isLoading={isFetchingProducts} />

            <Form.Item
              name="selectedProducts"
              rules={[
                {
                  required: selectedProducts.length === 0,
                  message: 'Adicione pelo menos um item.',
                },
              ]}
            >
              <ListingItensField isInSelection={false} data={selectedProducts} />
            </Form.Item>
          </S.Column>

          <S.Row>
            <Form.Item
              label={<S.Label>Palavras similares</S.Label>}
              name="key_words"
              rules={[{ required: true, message: 'Informe as palavras similares do kit.' }]}
            >
              <Select
                mode="tags"
                tokenSeparators={[',']}
                placeholder="Ex: Gamer"
                notFoundContent="Adicione uma palavra"
              />
            </Form.Item>

            <Form.Item
              label={<S.Label>Text link</S.Label>}
              name="link_id"
              rules={[{ required: true, message: 'Informe o texto do link do kit.' }]}
            >
              <Input placeholder="Ex: https://www.seulink.com.br" />
            </Form.Item>
          </S.Row>

          <Form.Item
            label={<S.Label>Título da página</S.Label>}
            name="title"
            rules={[{ required: true, message: 'Informe o título da página do kit.' }]}
          >
            <Input placeholder="Ex: Kit Gamer" />
          </Form.Item>

          <S.Row>
            <Form.Item
              label={<S.Label>Peso para frete (Kg)</S.Label>}
              name="packaged_weight_kg"
              rules={[{ required: true, message: 'Informe o peso do kit.' }]}
            >
              <S.NumberInput min={0} type="number" placeholder="Ex: 1" />
            </Form.Item>

            <Form.Item
              label={<S.Label>Altura para frete</S.Label>}
              name="packaged_height"
              rules={[{ required: true, message: 'Informe a altura do kit.' }]}
            >
              <S.NumberInput min={0} type="number" placeholder="Ex: 10" />
            </Form.Item>

            <Form.Item
              label={<S.Label>Largura para frete</S.Label>}
              name="packaged_width"
              rules={[{ required: true, message: 'Informe largura do kit.' }]}
            >
              <S.NumberInput min={0} type="number" placeholder="Ex: 10" />
            </Form.Item>

            <Form.Item
              label={<S.Label>Comprimento</S.Label>}
              name="packaged_length"
              rules={[{ required: true, message: 'Informe o comprimento do kit.' }]}
            >
              <S.NumberInput min={0} type="number" placeholder="Ex: 10" />
            </Form.Item>
          </S.Row>

          <Form.Item
            label={<S.Label>Descrição (Title Tag)</S.Label>}
            name="meta_tag_description"
            rules={[{ required: true, message: 'Informe a descrição title tag do kit.' }]}
          >
            <Input placeholder="Ex: Kit" />
          </Form.Item>

          <div>
            <Form.Item
              label={<S.Label>Descrição</S.Label>}
              name="description"
              rules={[{ required: true, message: 'Informe uma descrição para o kit.' }]}
            >
              <S.TextAreaContainer
                maxLength={300}
                autoSize={true}
                placeholder="Ex: Componentes do kit"
              />
            </Form.Item>
            <S.MaxLength>{descriptionLength}/300 caracteres</S.MaxLength>
          </div>
        </S.Container>
      </S.CardContent>
    </S.Card>
  )
}
