import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ArrowLeftOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Divider, Image, Form, Typography, Modal, Badge, Switch, Flex } from 'antd'
import axios from 'axios'
import DOMPurify from 'dompurify'
import { Masonry } from 'masonic'
import { toast } from 'sonner'

import * as S from './styles'

import { useRolesStore } from '@/app'
import {
  ApiError,
  formatDate,
  imgFallback,
  ModuleKey,
  RoleKey,
  toastError,
  useToggle,
  useRolePermissions,
} from '@/common'
import { ReturnButton } from '@/components'
import { TitleTabs } from '@/components/Layout/PageLayout'
import { CreateAgencyModal, SelectAgenciesModal } from '@/features/agency'
import {
  BGData,
  ImagePack,
  PublicationSummary,
  BGDataFormData,
  formatProductType,
  SkuFile,
  useUpdateBgDataMutation,
  useImagePackMutation,
  BgHistoryModal,
  defaultBGErrorMessages,
  ErrorRegisteringBGModal,
  FilterIcon,
  Showcase,
  BgStatusTags,
  UpdateBgDataMutation,
  ImagePackMutation,
  ProductPageSkeleton,
  ActionButtons,
  useChangeStatusMutation,
  DiscontinuedModal,
  type ProductDownloadType,
  bgQueries,
  BgFileManagementModal,
  EditGeneralInformationModal,
  changeStatusErrorMessages,
  MarketplaceDataDisplay,
  useUpdateProductImagePackMutation,
  BgFileUploadData,
  StartIntegrationModal,
  Cisp1IntegrationModal,
  ChannelSelector,
  BgIntegrationChannels,
} from '@/features/BG'
import BgServiceCard from '@/features/BG/components/BgView/BgServiceCard'
import { PageLayout } from '@/layouts'
import { api } from '@/services'

export function ProductPage() {
  const { id: paramProductId } = useParams()

  const isSystemAdmin = useRolesStore((state) => state.isSystemAdmin)
  const commercialPermissions = useRolePermissions(ModuleKey.COMMERCIAL, RoleKey.COMMERCIAL)
  const ecommercePermissions = useRolePermissions(ModuleKey.ECOMMERCE, RoleKey.ECOMMERCE)

  const { data, isLoading, error, refetch } = useQuery(bgQueries.detail(paramProductId as string))

  const updateBgDataMutation = useUpdateBgDataMutation()
  const imagePackMutation = useImagePackMutation()
  // const publishVtexMutation = usePublishVtexMutation()

  const changeStatusMutation = useChangeStatusMutation()

  const updateImagePackMutation = useUpdateProductImagePackMutation()

  const [currentStep, setCurrentStep] = useState(0)
  const [isEditing, toggleEdit] = useToggle(false)
  const [isStartingIntegrations, toggleStartIntegration] = useToggle(false)
  const [isSelectingAgencies, toggleSelectAgencies] = useToggle(false)
  const [isCreatingAgency, toggleCreateAgency] = useToggle(false)
  const [isProductHistoryOpen, toggleProductHistory] = useToggle(false)
  const [isErrorModalOpen, toggleErrorModal] = useToggle(false)
  const [isDiscontinuedModalOpen, toggleDiscontinuedModal] = useToggle(false)
  const [isBgFilesModalOpen, toggleBgFilesModal] = useToggle(false)
  const [isEditGeneralInformationModalOpen, toggleEditGeneralInformationModal] = useToggle(false)
  const [isCisp1ModalOpen, toggleCisp1Modal] = useToggle(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [imagePackData, setImagePackData] = useState<BgFileUploadData[]>([])
  const [form] = Form.useForm<BGDataFormData>()
  const [formValues, setFormValues] = useState<BGDataFormData>({} as BGDataFormData)
  const [publishInAnymarket, setPublishInAnymarket] = useState(
    () => data?.integrated_channels?.anymarket?.integrated || false,
  )
  const [integrationChannels, setIntegrationChannels] = useState<BgIntegrationChannels>({})

  useEffect(() => {
    if (error) {
      toast.error(
        toastError({
          error: error as ApiError,
          defaultMessage: 'Falha ao carregar dados do produto.',
          errorMessages: new Map([
            ['Product not found', 'Produto não encontrado'],
            ['Not Found', 'Produto não encontrado'],
            ['The given product id is not valid', 'ID de produto inválido'],
          ]),
        }),
      )
    }
  }, [error])

  useEffect(() => {
    if (data?.integrated_channels?.anymarket?.integrated) {
      setPublishInAnymarket(data?.integrated_channels?.anymarket?.integrated)
    }

    const initialImagesPackData = data?.sku?.sku_files?.map((file) => ({
      url: file.filename_url,
      filename: file.s3_filename,
      file: new File([], file.name, { type: 'image/jpeg' }),
    }))

    if (initialImagesPackData) {
      setImagePackData(initialImagesPackData)
    }

    if (data?.integrated_channels) {
      const channels: BgIntegrationChannels = {}

      Object.keys(data?.integrated_channels).forEach((elem) => {
        channels[elem] = data?.integrated_channels[elem]?.integrated
      })

      setIntegrationChannels(channels)
    }
  }, [data])

  const hasIntegratedChannel = useMemo(() => {
    if (data?.integrated_channels) {
      return Object.values(data?.integrated_channels).some((item) => item.integrated)
    }

    return false
  }, [data])

  const defaultValues = useMemo(
    () => ({
      productId: data?.integrated_channels?.vtex?.id || paramProductId || '', // show vtex_id but is actually bgId
      productName: data?.name || data?.model || '',
      similarWords: data?.key_words || '',
      sku: data?.sku?.vtex_id || data?.sku?.id || '', // show vtex_id but is actually data.sku.id
      skuName: data?.sku?.name || '',
      partNumber: data?.ref_id || '',
      textLink: data?.link_id || '',
      pageTitle: data?.title || '',
      brand_id: data?.brand_id || 0,
      category: data?.category_name || '',
      descriptionTitleTag: data?.meta_tag_description || '',
      descriptionTechnicalSheet: data?.description || '',
      filters: data?.filters || [],
      MGZProductName: data?.technical_specification?.MGZ_ProductName?.replace(/<[^>]+>/g, '') || '',
      CRFProductName: data?.technical_specification?.CRF_ProductName?.replace(/<[^>]+>/g, '') || '',
      CBHProductName: data?.technical_specification?.CBH_ProductName?.replace(/<[^>]+>/g, '') || '',
      // filters: data?.specifications_vtex ? data?.specifications_vtex?.filters : [],
      icons: data?.icons || [],
      // icons: data?.specifications_vtex ? data?.specifications_vtex?.icons : [],
      showcase: (data?.sku?.sku_files || []) as SkuFile[],
      // showcase: [],
      excludedFiles: [],
    }),
    [data, paramProductId],
  )

  const isMutating = updateBgDataMutation.isPending || imagePackMutation.isPending
  // || publishVtexMutation.isPending

  const steps = [
    {
      title: 'Dados do BG',
      content: (
        <BGData
          currentSelectedFilters={formValues.filters}
          currentSelectedIcons={formValues.icons}
          initialValues={{
            filters: data?.filters || [],
            icons: data?.icons || [],
          }}
          productType={{
            name: data?.product_type_name,
            vtex_id: data?.product_type_vtex_id,
          }}
          vtexCategoryId={data?.category_vtex_id as number}
        />
      ),
      disabled: currentStep === 0 || isMutating,
    },
    {
      title: 'Pack de Imagens',
      content: (
        <ImagePack
          data={
            formValues.showcase?.length > 0
              ? formValues.showcase
              : data?.sku?.sku_files
              ? (data?.sku?.sku_files as SkuFile[])
              : []
            // : (data?.sku.files as SkuFile[] | [])
          }
          imagePackData={imagePackData}
          setImagePackData={setImagePackData}
        />
      ),
      disabled: currentStep <= 1 || isMutating,
    },
    {
      title: 'Pendente de Publicação',
      content: <PublicationSummary data={formValues} />,
      disabled: currentStep <= 2 || isMutating,
    },
  ]

  const productTabItems = [
    {
      key: 'bg-details',
      label: 'DETALHES DO BG',
      children: (
        <S.ProductSpecsContainer>
          {data?.technical_specification ? (
            <div>
              <S.ExternalCharacCard>
                <strong>Características Externas:</strong>
                <S.WrapingText
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      !data?.external_characteristics ||
                        data?.external_characteristics === '<p></p>'
                        ? '-'
                        : data?.external_characteristics,
                    ),
                  }}
                />
              </S.ExternalCharacCard>

              <Masonry
                items={Object.entries(data?.technical_specification)}
                render={(data) => {
                  const key = data.data[0]
                  const values = data.data[1]

                  return (
                    <S.Card key={key}>
                      <strong>{key}:</strong>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(String(values.length > 0 ? values : '-')),
                        }}
                      />
                    </S.Card>
                  )
                }}
                rowGutter={12}
                columnGutter={16}
                maxColumnCount={2}
                overscanBy={10}
              />
            </div>
          ) : (
            <S.ErrorContainer>
              <S.ExclamationIcon />
              <Typography>
                Ops, tivemos um problema ao carregar este painel. Por favor, recarregue a página!
              </Typography>
            </S.ErrorContainer>
          )}

          {(data?.sku?.sku_files?.length as number) > 1 && (
            <S.PackImageTitle>Pack de Imagens</S.PackImageTitle>
          )}

          <S.ImageViewer>
            {data?.sku?.sku_files?.map((src, index: number) => {
              if (index === 0) {
                return null
              }

              return (
                <div key={index}>
                  <Image
                    height={164}
                    width={164}
                    key={index}
                    src={src.filename_url}
                    preview={false}
                    style={{
                      objectFit: 'contain',
                      borderRadius: 6,
                      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
                    }}
                  />
                </div>
              )
            })}
          </S.ImageViewer>
        </S.ProductSpecsContainer>
      ),
    },
    {
      key: 'services',
      label: 'SERVIÇOS',
      disabled: !data?.services.length,
      children: (
        <S.ServicesContainer>
          {data?.services.map((service) => <BgServiceCard key={service.name} service={service} />)}
        </S.ServicesContainer>
      ),
    },
    {
      key: 'publication-details',
      label: 'DETALHES DA PUBLICAÇÃO',
      children: (
        <S.ProductSpecsContainer>
          <PublicationSummary data={defaultValues} />
        </S.ProductSpecsContainer>
      ),
    },
  ]

  const tabItems = productTabItems.filter((item) => {
    const hasTabAccess =
      isSystemAdmin ||
      ecommercePermissions?.isAdmin ||
      ecommercePermissions?.canRead ||
      commercialPermissions?.isAdmin ||
      commercialPermissions?.canRead

    const shouldShowPublicationDetails =
      hasTabAccess && (data?.status === 'PENDENTE DE PRECIFICAÇÃO' || data?.status === 'PUBLICADO')

    if (!shouldShowPublicationDetails && item.key === 'publication-details') {
      return item.key !== 'publication-details'
    }

    return item
  })

  async function handleDownloadFiles(type: ProductDownloadType) {
    const toastId = toast.loading('Preparando download...')

    setIsDownloading(true)

    const ENDPOINTS = {
      docx: `/products/${paramProductId}/specification_docx_url`,
      bgFiles: `/products/support_files/zip/${paramProductId}`,
    }

    const SUCCESS_MESSAGES = {
      docx: 'Download do docx concluído',
      bgFiles: 'Download dos arquivos concluído',
    }

    const DOWNLOAD_NAMES = {
      docx: 'ficha_tecnica.docx',
      bgFiles: 'arquivos_bg.zip',
    }

    try {
      const { data } = await api.get<{ url: string; url_download: string }>(ENDPOINTS[type])

      if (!data.url && !data.url_download) {
        console.error('Download error: URL not found')
        return
      }

      const downloadLink = document.createElement('a', { is: 'download-link' })

      downloadLink.href = data.url || data.url_download
      downloadLink.setAttribute('download', DOWNLOAD_NAMES[type])
      downloadLink.download = DOWNLOAD_NAMES[type]
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)

      toast.success(SUCCESS_MESSAGES[type], {
        id: toastId,
      })
    } catch (err) {
      console.error('Download error:', err)
      toast.error('Falha no download', {
        id: toastId,
      })
    } finally {
      setIsDownloading(false)
    }
  }

  function startEdit(isEditing = false) {
    if (!isEditing) {
      if (
        data?.integrated_channels.cisp1.integrated &&
        !data?.integrated_channels.vtex.integrated
      ) {
        return toggleCisp1Modal()
      }
      return toggleStartIntegration()
    }

    return Modal.confirm({
      title: (
        <span>
          Deseja editar o produto <strong>{data?.ref_id}</strong> ?
        </span>
      ),
      centered: true,
      icon: <ExclamationCircleFilled style={{ color: '#FF8B27' }} />,
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: () => {
        toggleEdit()
      },
      footer: (_, { OkBtn, CancelBtn }) => (
        <S.ModalFooter>
          <CancelBtn />
          <OkBtn />
        </S.ModalFooter>
      ),
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          background: '#80c343',
        },
      },
      cancelButtonProps: {
        type: 'link',
        style: {
          color: '#80c343',
        },
      },
    })
  }

  function handleCancelEdit() {
    setCurrentStep(0)
    form.resetFields()
    toggleEdit()
  }

  function cancelErrorModal() {
    handleCancelEdit()
    toggleErrorModal()
  }

  function retryErrorModal() {
    handlePublishProduct()
    toggleErrorModal()
  }

  function updateFormAndAdvanceStep(formValues: BGDataFormData) {
    setFormValues(formValues)
    setCurrentStep((currentStep) => currentStep + 1)
  }

  function getFormValues() {
    let values = form.getFieldsValue()

    // check if values object is empty, if so, use formValues
    if (Object.keys(values).length === 0) {
      values = formValues
    }

    const bgDataFormValues: BGDataFormData = {
      ...values,
      filters: form.getFieldValue('filters').filter((item: FilterIcon) => item.values.length > 0), // remove empty values
      icons: form.getFieldValue('icons').filter((item: FilterIcon) => item.values.length > 0), // remove empty values
      showcase: form.getFieldValue('showcase'),
      excludedFiles: form.getFieldValue('excludedFiles'),
    }

    return bgDataFormValues
  }

  async function validateForm() {
    try {
      await form.validateFields() // validate fields in step 0

      // If it's not the ImagePack step or showcase is valid return true
      if (currentStep !== 1 || validateImagePackStep()) return true
    } catch (error: any) {
      console.error('ERROR', error)

      if (error.errorFields && error.errorFields.length > 0) {
        toast.error('Preencha todos os campos obrigatórios')
      }
    }

    return false
  }

  function validateImagePackStep() {
    const showcaseValues: Showcase[] = form.getFieldValue('showcase')
    const mainShowcaseItem = showcaseValues.find((item) => item.main === true)

    if (!showcaseValues || !mainShowcaseItem) {
      toast.error('Selecione uma vitrine para continuar')
      return false
    }

    return true
  }

  async function handleNextStep() {
    try {
      const isFormValid = await validateForm()

      if (!isFormValid) return

      const isImagePackStep = currentStep === 1

      if (isImagePackStep && !validateImagePackStep()) return

      updateFormAndAdvanceStep(getFormValues())
    } catch (e) {
      console.error(e)
    }

    //scroll to top
    window.scrollTo(0, 0)
  }

  async function handleUpdateProductData(updateProductPayload: UpdateBgDataMutation) {
    return updateBgDataMutation.mutateAsync(updateProductPayload)
  }

  async function handleUpdateImagePack(imagePackPayload: ImagePackMutation) {
    return imagePackMutation.mutateAsync(imagePackPayload)
  }

  async function handleUpdateImages(imagesPayload: any) {
    return updateImagePackMutation.mutateAsync(imagesPayload)
  }

  async function uploadImagePack(imagePackData: BgFileUploadData[]) {
    if (imagePackData.length === 0) {
      toast.error('Nenhum imagem encontrada.')
      throw new Error('Nenhum imagem encontrada.')
    }

    const uploadResults: { success: number; errors: number; failedMessages: string[] } = {
      success: 0,
      errors: 0,
      failedMessages: [],
    }

    const promises = imagePackData.map((data) => {
      return axios
        .put(data.url, data?.file, {
          headers: {
            'Content-Type': data?.file?.type,
          },
        })
        .then((response) => {
          uploadResults.success++
          return response
        })
        .catch((error) => {
          uploadResults.errors++
          uploadResults.failedMessages.push(
            `Falha no arquivo ${data?.file?.name}: ${error.message}`,
          )
          console.error(`ERROR during file upload ${data?.file?.name}:`, error)
          return error
        })
    })

    await Promise.all(promises)

    if (uploadResults.errors > 0) {
      toast.error(`Alguns arquivos não foram enviados corretamente.`)
      uploadResults.failedMessages.forEach((msg) => console.log(msg))
      throw new Error('Erro ao enviar alguns arquivos.')
    }
  }

  async function handlePublishProductOperations(
    productValues: any,
    saveAsDraft?: { shouldSave: boolean; draftStep: 'productData' | 'imagePack' },
  ) {
    const categoryId =
      productValues.category === data?.category_name ? data?.category_id : productValues.category

    const technicalSpecification = {
      ...data?.technical_specification,
      MGZ_ProductName: productValues.MGZProductName,
      CBH_ProductName: productValues.CBHProductName,
      CRF_ProductName: productValues.CRFProductName,
    }

    const updateProductPayload = {
      productId: paramProductId as string,
      brand_id: productValues.brand_id,
      description: productValues.descriptionTechnicalSheet,
      key_words: productValues.similarWords,
      sku_id: data?.sku.id as string,
      sku_name: productValues.skuName || data?.sku.name,
      link_id: productValues.textLink,
      name: productValues.productName,
      title: productValues.pageTitle,
      meta_tag_description: productValues.descriptionTitleTag,
      part_number: productValues.partNumber,
      filters: productValues.filters,
      icons: productValues.icons,
      category_id: String(categoryId),
      integrate_with_anymarket: publishInAnymarket,
      technical_specification: technicalSpecification,
    }

    await handleUpdateProductData(updateProductPayload)

    if (saveAsDraft?.shouldSave && saveAsDraft?.draftStep === 'productData') {
      return
    }

    const uploadImagePackPayload = imagePackData.filter((item) => item.file && item.file.size > 0)

    if (uploadImagePackPayload.length > 0) {
      await uploadImagePack(uploadImagePackPayload)
    }

    const imagesPayload = {
      files_excludeds: productValues.excludedFiles,
      files_includeds: productValues.showcase.map((item: SkuFile) => item.s3_filename),
      product_id: paramProductId,
      image_cover_bg: productValues.showcase.find((item: SkuFile) => item.is_cover_bg_image)
        ?.s3_filename,
      saveAsDraft: !!saveAsDraft?.shouldSave,
    }

    const result = await handleUpdateImages(imagesPayload)

    const imagePackPayload = {
      productId: paramProductId as string,
      skuId: data?.sku.id as string,
      imagePack: result.data.sku.sku_files.map((field: any, index: number) => ({
        name: field.name,
        main: !!field.main,
        order: index,
      })),
      saveAsDraft: saveAsDraft?.shouldSave || false,
    }

    await handleUpdateImagePack(imagePackPayload)
  }

  async function handleSaveDraft() {
    const isValid = await validateForm()

    if (!isValid) {
      toast.error('Preencha todos os campos obrigatórios')
      return
    }

    const productValues = getFormValues()

    try {
      const draftStep = currentStep === 0 ? 'productData' : 'imagePack'

      const saveAsDraftPromise = handlePublishProductOperations(productValues, {
        shouldSave: true,
        draftStep,
      })

      toast.promise(saveAsDraftPromise, {
        loading: 'Salvando rascunho...',
        success: (
          <S.ToastContainer>
            <S.CircleOutlined />

            <div>
              Rascunho <span style={{ fontWeight: 'bold' }}>salvo</span> com{' '}
              <span style={{ fontWeight: 'bold' }}>sucesso</span>!
            </div>
          </S.ToastContainer>
        ),
        className: 'toast-sucess',
        error: (error: ApiError) =>
          toastError({
            error,
            defaultMessage: 'Não foi possível salvar o rascunho.',
            errorMessages: defaultBGErrorMessages,
          }),
      })
    } catch (err) {
      console.error('Error saving draft', err)
    }
  }

  async function handlePublishProduct() {
    const productValues = getFormValues()

    try {
      const updateProductPromise = handlePublishProductOperations(productValues)

      toast.promise(updateProductPromise, {
        loading: 'Publicando...',
        success: (
          <S.ToastContainer>
            <S.CircleOutlined />

            {data?.status === 'EM RASCUNHO NO E-COMMERCE' ? (
              <div>
                Produto <span style={{ fontWeight: 'bold' }}>enviado para precificação</span>!
              </div>
            ) : (
              <div>
                Produto <span style={{ fontWeight: 'bold' }}>editado</span> com sucesso!
              </div>
            )}
          </S.ToastContainer>
        ),
        className: 'toast-sucess',
        error: (error: ApiError) => {
          const defaultMessge =
            data?.status === 'EM RASCUNHO NO E-COMMERCE'
              ? 'Não foi possível enviar o produto para precificação.'
              : 'Falha ao editar produto'

          return toastError({
            error,
            defaultMessage: defaultMessge,
            errorMessages: defaultBGErrorMessages,
          })
        },
      })

      await updateProductPromise
      handleCancelEdit()

      if (data?.integrated_channels?.cisp1?.integrated) {
        toggleCisp1Modal()
      }
    } catch (err) {
      console.error('Error publishing product', err)
      toggleErrorModal()
    }
  }

  function handleChangeProductPublicationStatus(newStatus: boolean) {
    const promise = changeStatusMutation.mutateAsync({
      product_id: paramProductId as string,
      status: newStatus,
    })

    toast.promise(promise, {
      loading: 'Alterando status...',
      success: (
        <S.ToastContainer>
          <S.CircleOutlined />

          <div>
            Produto{' '}
            <span style={{ fontWeight: 'bold' }}>
              {newStatus === true ? 'publicado' : 'despublicado'}
            </span>{' '}
            com <span style={{ fontWeight: 'bold' }}>sucesso</span>!
          </div>
        </S.ToastContainer>
      ),
      className: 'toast-sucess',
      error: (error: ApiError) =>
        toastError({
          error,
          defaultMessage: `Falha ao ${
            newStatus === true ? 'publicar' : 'despublicar'
          } produto. Tente novamente mais tarde.`,
          errorMessages: changeStatusErrorMessages,
        }),
    })
  }

  function confirmChangePublicationStatus(newStatus: boolean) {
    Modal.confirm({
      title: `${newStatus === true ? 'Publicar' : 'Despublicar'} o produto?`,
      content: (
        <span>
          O novo status do produto será:{' '}
          <strong>{newStatus === true ? 'Publicado' : 'Despublicado'}</strong>.
        </span>
      ),
      centered: true,
      icon: <ExclamationCircleFilled style={{ color: '#FAAD14' }} />,
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => handleChangeProductPublicationStatus(newStatus),
      footer: (_, { OkBtn, CancelBtn }) => (
        <S.ModalFooter>
          <CancelBtn />
          <OkBtn />
        </S.ModalFooter>
      ),
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          background: '#80c343',
        },
      },
      cancelButtonProps: {
        danger: true,
      },
    })
  }

  const BackButton = () => {
    return (
      <>
        {!isEditing && <ReturnButton />}

        {isEditing && (
          <>
            {currentStep <= 0 ? (
              <S.BackButton type="link" icon={<ArrowLeftOutlined />} onClick={handleCancelEdit}>
                Voltar
              </S.BackButton>
            ) : (
              <S.BackButton
                type="link"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  setCurrentStep((currentStep) => currentStep - 1)
                }}
              >
                Voltar
              </S.BackButton>
            )}
          </>
        )}
      </>
    )
  }
  const handleVtexCisp1Integration = () => {
    toggleEdit()
    toggleStartIntegration()
  }

  return (
    <PageLayout title="BGView">
      {!hasIntegratedChannel && data?.status === 'EM RASCUNHO NO E-COMMERCE' && (
        <S.ChannelAlert
          message={
            <S.AlertMessage>
              Antes de Habilitar o Botão de <strong>Iniciar Integração</strong> é necessário Ativar
              os Canais e em seguida,<strong> Salvar as Ativações</strong>
            </S.AlertMessage>
          }
          type="warning"
          showIcon
          closable
        />
      )}
      {isBgFilesModalOpen && (
        <BgFileManagementModal
          isOpen={isBgFilesModalOpen}
          onClose={toggleBgFilesModal}
          productId={data?.id as string}
          handleDownloadFiles={handleDownloadFiles}
          isDownloading={isDownloading}
        />
      )}

      {isProductHistoryOpen && (
        <BgHistoryModal
          isOpen={isProductHistoryOpen}
          onClose={toggleProductHistory}
          productId={data?.id as string}
        />
      )}

      {isStartingIntegrations && (
        <StartIntegrationModal
          isOpen={isStartingIntegrations}
          onClose={toggleStartIntegration}
          handleStartIntegrating={handleVtexCisp1Integration}
          integrationChannels={{
            vtex: Boolean(data?.integrated_channels?.vtex?.integrated),
            cisp1: Boolean(data?.integrated_channels?.cisp1?.integrated),
          }}
          bgName={data?.ref_id || ''}
        />
      )}

      {isSelectingAgencies && (
        <SelectAgenciesModal
          productId={paramProductId as string}
          isOpen={isSelectingAgencies}
          onClose={toggleSelectAgencies}
          toggleCreateAgency={toggleCreateAgency}
        />
      )}

      {isCreatingAgency && (
        <CreateAgencyModal isOpen={isCreatingAgency} onClose={toggleCreateAgency} />
      )}

      {isCisp1ModalOpen && (
        <Cisp1IntegrationModal
          isOpen={isCisp1ModalOpen}
          onClose={toggleCisp1Modal}
          productId={paramProductId as string}
        />
      )}

      {isErrorModalOpen && (
        <ErrorRegisteringBGModal
          isOpen={isErrorModalOpen}
          onClose={cancelErrorModal}
          onConfirm={retryErrorModal}
          bgSku={data?.ref_id || ''}
        />
      )}

      {isDiscontinuedModalOpen && (
        <DiscontinuedModal
          isOpen={isDiscontinuedModalOpen}
          onClose={toggleDiscontinuedModal}
          data={data}
        />
      )}

      {isEditGeneralInformationModalOpen && (
        <EditGeneralInformationModal
          productData={data}
          isOpen={isEditGeneralInformationModalOpen}
          onClose={toggleEditGeneralInformationModal}
        />
      )}

      <S.Container>
        <S.Header>
          <S.ButtonsContainer>
            <BackButton />

            {!isLoading && (
              <S.ActionButtonsContainer>
                <ActionButtons
                  productId={paramProductId as string}
                  status={data?.status}
                  isEditing={isEditing}
                  currentStep={currentStep}
                  isLoading={updateBgDataMutation.isPending || imagePackMutation.isPending}
                  handleStartEditing={startEdit}
                  toggleProductHistory={toggleProductHistory}
                  toggleProductDiscontinued={toggleDiscontinuedModal}
                  toggleAgenciesSelect={toggleSelectAgencies}
                  toggleBgFiles={toggleBgFilesModal}
                  toggleEditGeneralInformation={toggleEditGeneralInformationModal}
                  handleNextStep={handleNextStep}
                  handleSaveDraft={handleSaveDraft}
                  handlePublishProduct={handlePublishProduct}
                  hasIntegratedChannel={hasIntegratedChannel}
                />
              </S.ActionButtonsContainer>
            )}
          </S.ButtonsContainer>

          <S.StepsContainer>
            {!isLoading && !isEditing && (
              <S.Date>
                Atualizado em{' '}
                <strong>
                  {formatDate({ date: data?.created_at as string, dateFormat: 'DD/MM/YYYY' })}
                </strong>
              </S.Date>
            )}

            <S.Title>
              <span>Visualização do produto</span>

              <Divider type="vertical" style={{ height: 'auto' }} />

              <strong>{data?.ref_id || ''}</strong>
            </S.Title>

            <S.Subtitle>SKU: {data && data?.sku?.vtex_id ? data?.sku?.vtex_id : '--'}</S.Subtitle>

            {isEditing && (
              <S.Steps
                current={currentStep}
                items={steps}
                onChange={(step) => setCurrentStep(step)}
              />
            )}
          </S.StepsContainer>

          <S.PriceContainer>
            <MarketplaceDataDisplay
              marketplace="vtex"
              price={
                (data?.status === 'PUBLICADO' || data?.status === 'DESPUBLICADO') &&
                data?.sku?.prices.vtex
                  ? data.sku.prices.vtex
                  : undefined
              }
              stock={{
                value: data?.sku?.stock_quantity_vtex,
                isLowStock: data?.sku?.low_stock_vtex,
              }}
            />

            <S.PriceDivider type="vertical" />

            <MarketplaceDataDisplay
              marketplace="anymarket"
              price={
                (data?.status === 'PUBLICADO' || data?.status === 'DESPUBLICADO') &&
                data?.sku?.prices.anymarket
                  ? data.sku.prices.anymarket
                  : undefined
              }
              stock={{
                value: data?.sku?.stock_quantity_anymarket,
                isLowStock: data?.sku?.low_stock_anymarket,
              }}
            />
          </S.PriceContainer>
        </S.Header>

        {isLoading ? (
          <ProductPageSkeleton />
        ) : (
          <>
            <S.BgProductInfoContainer>
              <S.BGInfoContainer>
                <S.imageContainer>
                  <Image
                    height={274}
                    width={274}
                    preview={false}
                    fallback={imgFallback}
                    alt={`Hero image de ${data?.model}`}
                    src={data?.sku?.sku_files?.[0]?.filename_url}
                    style={{ objectFit: 'contain', borderRadius: 6 }}
                  />
                </S.imageContainer>

                <S.InfoContainer>
                  <S.StatusContainer>
                    <S.StatusContent>
                      <BgStatusTags showDescription status={data?.status} isBgView />

                      {data?.status === 'PENDENTE DE PRECIFICAÇÃO' && (
                        <Badge count="Não publicado" />
                      )}
                    </S.StatusContent>

                    {!isEditing &&
                      (isSystemAdmin ||
                        ecommercePermissions?.isAdmin ||
                        ecommercePermissions?.canUpdate) &&
                      (data?.status === 'PUBLICADO' || data?.status === 'DESPUBLICADO') && (
                        <Switch
                          disabled={changeStatusMutation.isPending}
                          checked={data?.status === 'PUBLICADO'}
                          onChange={(newStatus) => confirmChangePublicationStatus(newStatus)}
                          checkedChildren={'Publicado'}
                          unCheckedChildren={'Despublicado'}
                        />
                      )}
                  </S.StatusContainer>
                  <Flex gap={16}>
                    <S.SummaryContainer>
                      <S.ProductCategory>
                        {formatProductType(data?.product_type_name as string) ||
                          data?.product_type_name}

                        <Divider type="vertical" />

                        <strong>{data?.category_name}</strong>
                      </S.ProductCategory>

                      <S.DisplayName>{data?.name || data?.model}</S.DisplayName>

                      <S.Summary>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(data?.overview as string),
                          }}
                        />
                      </S.Summary>
                    </S.SummaryContainer>
                    <ChannelSelector
                      productId={paramProductId}
                      integrationChannels={integrationChannels}
                      setIntegrationChannels={setIntegrationChannels}
                      prices={data?.sku?.prices}
                      status={data?.status}
                      stock={{
                        vtex: {
                          stockQuantity: data?.sku.stock_quantity_vtex,
                          isLowStock: data?.sku.low_stock_vtex,
                        },
                        anymarket: {
                          stockQuantity: data?.sku.stock_quantity_anymarket,
                          isLowStock: data?.sku.low_stock_anymarket,
                        },
                      }}
                      refetch={refetch}
                    />
                  </Flex>
                </S.InfoContainer>
              </S.BGInfoContainer>

              {!isEditing ? (
                <TitleTabs items={tabItems} defaultActiveKey="bg-details" />
              ) : (
                <Form
                  form={form}
                  layout="vertical"
                  validateTrigger="onChange"
                  initialValues={{ ...defaultValues }}
                >
                  <S.Content>{steps[currentStep]?.content}</S.Content>
                </Form>
              )}
            </S.BgProductInfoContainer>

            <S.FooterButtonContainer>
              <BackButton />

              <S.ActionButtonsContainer>
                <ActionButtons
                  productId={paramProductId as string}
                  status={data?.status}
                  isEditing={isEditing}
                  currentStep={currentStep}
                  isLoading={updateBgDataMutation.isPending || imagePackMutation.isPending}
                  handleStartEditing={startEdit}
                  toggleProductHistory={toggleProductHistory}
                  toggleProductDiscontinued={toggleDiscontinuedModal}
                  toggleAgenciesSelect={toggleSelectAgencies}
                  toggleBgFiles={toggleBgFilesModal}
                  toggleEditGeneralInformation={toggleEditGeneralInformationModal}
                  handleNextStep={handleNextStep}
                  handleSaveDraft={handleSaveDraft}
                  handlePublishProduct={handlePublishProduct}
                  hasIntegratedChannel={hasIntegratedChannel}
                />
              </S.ActionButtonsContainer>
            </S.FooterButtonContainer>
          </>
        )}
      </S.Container>
    </PageLayout>
  )
}
