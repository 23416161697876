type FormatCurrencyType = {
  value: string | number
  type?: 'currency' | 'decimal'
}

const formatCurrency = ({ value, type = 'currency' }: FormatCurrencyType) => {
  if (!value) return 'R$ 00.000,00'

  return new Intl.NumberFormat('pt-BR', {
    style: type === 'currency' ? 'currency' : 'decimal',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(Number(value))
}

export { formatCurrency }
