import { useMutation, useQueryClient } from '@tanstack/react-query'

import { specificationQueries } from '../queries'

import { api } from '@/services'

interface CreateSpecifications {
  field_type_id: number
  product_type_vtex_id: number
  field_group_id: number
  name: string
  position: number
}

export const createSpecificationsErrorMessages = new Map([
  ['Product type not found', 'Tipo do produto não encontrado'],
  ['The given product id is not valid', 'O id do produto fornecido não é válido'],
  ['Error creating specification in VTEX', 'Erro ao criar especificação na VTEX'],
])

export const useCreateSpecificationsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: CreateSpecifications) => {
      return api.post('/specifications', data)
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: specificationQueries.all() }),
  })
}
