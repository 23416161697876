import { useEffect } from 'react'

import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { DatePicker, FormInstance, Collapse, CollapseProps, Select, Form, Radio } from 'antd'
import dayjs from 'dayjs'
import { toast } from 'sonner'

import * as S from './styles'

import { useBGProductFilters } from '@/app/stores/filter/bg-product-filter'
import { ApiError, filtersRemap, productStatusSelectOptions, toastError } from '@/common'
import { ProductStatus } from '@/features/BG'
import { categoryQueries, formatCategories } from '@/features/categories'
import { productTypeQueries } from '@/features/product-types'

export type BGProductFilterFormData = {
  initial_date?: any
  final_date?: any
  product_type_name: string | undefined
  category_name?: string | undefined
  product_status?: ProductStatus | undefined
  is_kit?: boolean | undefined
  integrated_with_vtex?: boolean | undefined
  vtex_legacy?: boolean | undefined
}

type BGProductFilterFormProps = {
  form: FormInstance
  onSubmit: (data: BGProductFilterFormData) => void
}

export const BGProductFilterForm = ({ form, onSubmit }: BGProductFilterFormProps) => {
  const { data: types, isLoading: isProductTypesLoading } = useQuery(
    productTypeQueries.list({
      page: 1,
      per_page: 100,
    }),
  )

  const selectedProductType = Form.useWatch('product_type_name', form)
  const selectedCategory = Form.useWatch('category_name', form)

  const {
    data: categoriesResponse,
    isLoading: isCategoriesLoading,
    error,
  } = useQuery(
    categoryQueries.list({
      page: 1,
      per_page: 100,
      product_type_name: selectedProductType,
    }),
  )

  const productTypesSelectOptions = types?.product_types?.map((productType) => ({
    label: filtersRemap.get(productType.name) || productType.name,
    value: productType.name,
  }))

  const formattedCategories = formatCategories(categoriesResponse?.categories || [])

  const categorySelectOptions = selectedProductType
    ? [
        {
          label: filtersRemap.get(selectedProductType) || selectedProductType,
          options: categoriesResponse?.categories.map((category) => ({
            value: category.name,
            label: category.name,
          })),
        },
      ]
    : Object.entries(formattedCategories).map(([key, value]) => ({
        label: key,
        options: value.map((category) => ({
          key: category.id,
          label: category.name,
          value: category.name,
        })),
      }))

  const bgProductFilters = useBGProductFilters((state) => state.filters)

  useEffect(() => {
    if (error) {
      toast.error(
        toastError({
          error: error as ApiError,
          defaultMessage: 'Erro ao buscar categor',
          errorMessages: new Map([
            [
              'Invalid per_page or page value',
              'Paginação ou quantidade de itens por página inválida',
            ],
          ]),
        }),
      )
    }
  }, [error])

  useEffect(() => {
    const initialValues = {
      ...form.getFieldsValue(),
      ...bgProductFilters,
      initial_date: bgProductFilters.initial_date
        ? dayjs(bgProductFilters.initial_date)
        : undefined,
      final_date: bgProductFilters.final_date ? dayjs(bgProductFilters.final_date) : undefined,
    }

    form.setFieldsValue(initialValues)
  }, [form, bgProductFilters])

  useEffect(() => {
    if (selectedCategory && categoriesResponse?.categories) {
      const selectedCategoryOption = categoriesResponse.categories.find(
        (category) => category.name === selectedCategory,
      )?.name

      if (!selectedCategoryOption) {
        form.setFieldsValue({ category_name: '' })
      }
    }
  }, [categoriesResponse?.categories, form, selectedCategory])

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Data',
      children: (
        <S.DateContainer>
          <Form.Item name="initial_date">
            <DatePicker placeholder="Data Inicial" format={'DD/MM/YYYY'} />
          </Form.Item>

          <Form.Item name="final_date">
            <DatePicker placeholder="Data Final" format={'DD/MM/YYYY'} />
          </Form.Item>
        </S.DateContainer>
      ),
    },
    {
      key: '2',
      label: 'Tipo de Produto',
      children: (
        <Form.Item name="product_type_name">
          <Select allowClear options={productTypesSelectOptions} loading={isProductTypesLoading} />
        </Form.Item>
      ),
    },
    {
      key: '3',
      label: 'Categoria',
      children: (
        <Form.Item name="category_name">
          <Select
            allowClear
            options={categorySelectOptions}
            loading={!!selectedProductType && isCategoriesLoading}
          />
        </Form.Item>
      ),
    },
    {
      key: '4',
      label: 'Status',
      children: (
        <Form.Item name="product_status">
          <Select allowClear options={productStatusSelectOptions} />
        </Form.Item>
      ),
    },
    {
      key: '5',
      label: 'Kit',
      children: (
        <Form.Item name="is_kit">
          <Radio.Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Radio.Group>
        </Form.Item>
      ),
    },
    {
      key: '6',
      label: 'Integrados na VTEX',
      children: (
        <Form.Item name="integrated_with_vtex">
          <Radio.Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Radio.Group>
        </Form.Item>
      ),
    },
    {
      key: '7',
      label: 'Importados da VTEX',
      children: (
        <Form.Item name="vtex_legacy">
          <Radio.Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Radio.Group>
        </Form.Item>
      ),
    },
  ]

  return (
    <S.Form onFinish={onSubmit} layout="vertical" form={form}>
      <Collapse
        accordion
        defaultActiveKey={'1'}
        expandIconPosition="end"
        bordered={false}
        style={{ background: 'transparent' }}
        expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
        items={items}
      />
    </S.Form>
  )
}
