import { useMutation, useQueryClient } from '@tanstack/react-query'

import { specificationQueries } from '../queries'

import { useSpecificationFilters } from '@/app'
import { api } from '@/services'

interface EditCategoriesMutation {
  id: string
  field_type_id: number
  product_type_vtex_id: number
  field_group_id: number
  name: string
  position: number
}

export const editSpecificationsErrorMessages = new Map([
  ['Specification not found', 'Especificação não encontrada'],
  ['Product type not found', 'Tipo do produto não encontrado'],
  ['Specification name already exists.', 'Nome da especificação já existe.'],
  ['the given product id is not valid', 'O id do produto fornecido não é válido'],
  ['the given specification id is not valid', 'O id da especificação fornecido não é válido'],
  [
    `Error updating specification in VTEX: {error details}`,
    `Erro ao editar especificação na VTEX: {error details}`,
  ],
])

export const useEditSpecificationsMutation = () => {
  const queryClient = useQueryClient()
  const { filters } = useSpecificationFilters()

  return useMutation({
    mutationFn: async (data: EditCategoriesMutation) => {
      return api.put(`specifications/${data.id}`, data)
    },
    onMutate: async (editedSpecification) => {
      await queryClient.cancelQueries({ queryKey: specificationQueries.all() })

      const cachedSpecificationsList = queryClient.getQueryData(
        specificationQueries.list(filters).queryKey,
      )

      if (cachedSpecificationsList) {
        const updatedSpecificationsList = cachedSpecificationsList.specifications.map(
          (specification) => {
            if (specification.id === editedSpecification.id) {
              return {
                ...specification,
                ...editedSpecification,
              }
            }

            return specification
          },
        )

        queryClient.setQueryData(specificationQueries.list(filters).queryKey, {
          ...cachedSpecificationsList,
          specifications: updatedSpecificationsList,
        })
      }

      return cachedSpecificationsList
    },
    onError: (error, _, previousSpecifications) => {
      console.log(error)

      if (previousSpecifications) {
        queryClient.setQueryData(
          specificationQueries.list(filters).queryKey,
          previousSpecifications,
        )
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: specificationQueries.all() }),
  })
}
