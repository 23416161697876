import { useQueryClient, useMutation } from '@tanstack/react-query'

import { bgQueries } from '../../queries'

import { api } from '@/services'

interface PublishCisp1Mutation {
  productId: string
  price: number
}

export const usePublishCisp1Mutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ productId, price }: PublishCisp1Mutation) => {
      return api.post(`/products/${productId}/integrate_cisp1`, { price })
    },

    onSettled: () => queryClient.invalidateQueries({ queryKey: bgQueries.all() }),
  })
}
