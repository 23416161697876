import { styled } from 'styled-components'

export const Card = styled.div`
  display: flex;
  flex: 1;

  padding: 8px;

  flex-direction: column;
  gap: 8px;
  border-radius: 6px;

  background-color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  strong {
    font-size: 16px;
    font-weight: 700;
  }
`

export const ChannelsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;

  li {
    display: flex;
    flex-direction: column;
  }
`
