import { Button, Divider, Form } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { toastError, type ApiError, type BaseModalProps } from '@/common'
import { BaseModal } from '@/components'
import {
  BgEditGeneralInformationForm,
  editGeneralInformationErrorMessages,
  useEditGeneralInformationMutation,
  useProductExists,
  type Product,
} from '@/features/BG'

interface EditGeneralInformationModalProps extends BaseModalProps {
  productData: Product | undefined
}

export const EditGeneralInformationModal = ({
  isOpen,
  onClose,
  productData,
}: EditGeneralInformationModalProps) => {
  const [form] = Form.useForm()
  const editGeneralInformationMutation = useEditGeneralInformationMutation()
  const { isLoading, checkProductExists } = useProductExists()

  async function handleSubmit(data: { family: string; model: string }) {
    await form.validateFields()

    const productExistsResponse = await checkProductExists(data.model, '')

    if (!productExistsResponse) {
      return toast.error('Falha ao validar modelo do BG')
    }

    const { product_with_model_exists } = productExistsResponse
    // didn't update model
    const hasUneditedModel = data.model === productData?.model

    if (product_with_model_exists && !hasUneditedModel) {
      form.setFields([
        {
          name: 'model',
          errors: ['Modelo já cadastrado.'],
        },
      ])

      return toast.error('Modelo já cadastrado.')
    }

    try {
      const promise = editGeneralInformationMutation.mutateAsync({
        ...data,
        productId: productData?.id as string,
      })

      toast.promise(promise, {
        loading: 'Salvando alterações...',
        success: 'BG atualizado com sucesso!',
        error: (error: ApiError) =>
          toastError({
            error,
            defaultMessage: 'Erro ao atualizar BG.',
            errorMessages: editGeneralInformationErrorMessages,
          }),
      })

      await promise

      onClose()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title={'Editar informações'}
      footer={
        <>
          <Button
            type="link"
            onClick={onClose}
            disabled={editGeneralInformationMutation.isPending || isLoading}
          >
            Cancelar
          </Button>

          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={editGeneralInformationMutation.isPending || isLoading}
          >
            Salvar
          </Button>
        </>
      }
    >
      <S.Container>
        <S.InfoContainer>
          <S.Product>
            <span>BG</span>

            <Divider type="vertical" />

            <strong>{productData?.ref_id || ''}</strong>
          </S.Product>

          <S.Instructions>
            Edite o <strong>Modelo</strong> e <strong>Familia</strong> do BG.
          </S.Instructions>
        </S.InfoContainer>

        <Form
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
          requiredMark={'optional'}
          disabled={editGeneralInformationMutation.isPending || isLoading}
          initialValues={{
            model: productData?.model,
            family: productData?.family,
          }}
        >
          <BgEditGeneralInformationForm />
        </Form>
      </S.Container>
    </BaseModal>
  )
}
