import { useMutation, useQueryClient } from '@tanstack/react-query'

import { bgQueries } from '../../queries'
import type { UploadSupportFile } from '../../types'

import { api } from '@/services'

export type SaveSupportFilesMutation = {
  productId: string
  supportFiles: Array<UploadSupportFile>
}

export const saveSupportFilesErrorMessages = new Map([
  ['Not found', 'Não encontrado'],
  [
    'There are already support files for this product',
    'Já existem arquivos de suporte para este produto',
  ],
  ['The given product id is not valid', 'O produto fornecido não é válido'],
])

export const useSaveSupportFilesMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ productId, supportFiles }: SaveSupportFilesMutation) => {
      return api.post(`/products/support_files/${productId}`, supportFiles)
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: bgQueries.all() }),
  })
}
