import { Button as AntdButton, Skeleton, Steps as StepsAntd, Tag, Typography } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;

  .ant-typography {
    line-height: initial;
  }
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const FooterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`

export const BackButton = styled(AntdButton)`
  padding: 0;
  color: ${(props) =>
    props.type === 'primary' ? props.theme.colors.white : props.theme.colors.darkGreen};
`

export const Price = styled(Typography.Text)`
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
`

export const Date = styled(Typography.Text)`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
`

export const Button = styled(AntdButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 4px 15px;

  color: ${(props) =>
    props.type === 'default' ? props.theme.colors.darkGreen : props.theme.colors.white};
  border: ${(props) =>
    props.type === 'default'
      ? `1px solid ${props.theme.colors.darkGreen}`
      : props.theme.colors.brand};
`

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
`

export const Subtitle = styled.span`
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.customTokens.text.darkGray};
  padding: 4px 0;
  display: block;
`

export const BGInfoContainer = styled.div`
  display: flex;
  /* align-items: stretch; */
  height: fit-content;
  gap: 16px;
  /* flex: 1; */
`

export const imageContainer = styled.div`
  display: flex;
  align-items: center;

  max-height: ${rem(274)};
  max-width: ${rem(274)};
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  background-color: #fff;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;

  line-height: initial;
`

export const StatusTemplateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`

export const TemplateContent = styled(Typography.Text)`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;
`

export const TemplateText = styled(Typography.Text)`
  color: #6c6c6c;
`

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  gap: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  padding: ${rem(14)};
  border-radius: ${rem(6)};
`

export const Summary = styled.ul`
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
  flex-direction: column;
  gap: 4px;

  background-color: ${({ theme }) => theme.colors.white};
  font-size: ${rem(14)};

  > div {
    max-height: 200px;
    overflow-y: auto;
  }
  ul {
    li {
      list-style-type: disc;
      padding-left: 0 1rem;
      margin-left: 1rem;
    }
  }
  ol {
    li {
      list-style-type: decimal;
      padding-left: 0 1rem;
      margin-left: 1rem;
    }
  }
`

export const ProductCategory = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-size: ${rem(16)};
  }

  strong {
    font-size: ${rem(16)};
    font-weight: 700;
    color: rgba(0, 0, 0, 0.8);
  }
`

export const DisplayName = styled.h3`
  width: 100%;
  font-weight: 700;
  font-size: ${rem(20)};
  color: #434343;

  span {
    color: #000;
  }
`

export const Card = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;

  padding: 8px;
  border-radius: 6px;

  background-color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  word-break: break-word;
  strong {
    font-weight: 600;
  }

  .strong-title {
    font-size: 16px;
    font-weight: 700;
  }
`

export const ExternalCharacCard = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px;

  gap: 8px;
  border-radius: 6px;
  margin-bottom: 15px;

  background-color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  overflow-x: auto;

  strong {
    font-size: 16px;
    font-weight: 700;
  }
`

export const WrapingText = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap !important;
  word-break: break-word;
  max-height: ${rem(200)};
  overflow-x: auto;
  ol,
  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: ${rem(234)};
    padding-left: 4px;
    padding-bottom: 4px;
    column-gap: ${rem(18)};
    row-gap: 6px;
    overflow-x: auto;
  }
`

export const ImageViewer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-top: 28px;
  gap: 24px;

  width: 100%;
  max-width: calc(100vw - 116px);
  overflow: auto;

  padding: 12px 2px;
`

export const ProductSpecsContainer = styled.div`
  margin-top: 16px;
  ul {
    li {
      list-style-type: disc;
      padding-left: 0 1rem;
      margin-left: 1rem;
    }
  }
  ol {
    li {
      list-style-type: decimal;
      padding-left: 0 1rem;
      margin-left: 1rem;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`

export const SketchTag = styled(Tag)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${rem(27)};
  width: fit-content;

  color: ${({ theme }) => theme.colors.matteBlue};
  border: 1px solid ${({ theme }) => theme.colors.matteBlue};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};

  font-size: ${rem(14)};
`

export const PublishedTag = styled(Tag)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${rem(27)};
  width: fit-content;

  color: ${({ theme }) => theme.colors.navyBlue};
  border: 1px solid ${({ theme }) => theme.colors.navyBlue};
  border-radius: 6px;
  background-color: rgb(7, 83, 198, 0.1);

  font-size: ${rem(14)};
`

export const UnpublishedTag = styled(Tag)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${rem(27)};
  width: fit-content;

  color: ${({ theme }) => theme.colors.lightGray};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};

  font-size: ${rem(14)};
`

export const SkeletonTab = styled(Skeleton.Input)`
  width: 250px !important;

  padding-top: 28px;
`

export const Steps = styled(StepsAntd)``

export const SpecsContent = styled.div``

export const Title = styled.h1`
  font-weight: 600;
  font-size: ${rem(20)};
  line-height: 1.5rem;
  color: #434343;
  padding: 20px 0 0 0;
`

export const PackImageTitle = styled(Title)`
  padding: 4px 0;
`

export const SupportFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > div {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 4px;

    display: flex;
    align-items: center;
    gap: 4px;
  }
`
