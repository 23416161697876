import { Link } from 'react-router-dom'

import {
  AuditOutlined,
  CheckOutlined,
  EditOutlined,
  FileExcelOutlined,
  FormOutlined,
  ForwardOutlined,
  SendOutlined,
} from '@ant-design/icons'

import type { ProductStatus } from '../../types'
import { ViewBgFilesButton, ViewProductHistoryButton } from '../Button'
import * as S from './styles'

import { useRolesStore } from '@/app'
import { ModuleKey, RoleKey, useRolePermissions } from '@/common'

interface ActionButtonsProps {
  productId: string
  status?: ProductStatus
  isEditing: boolean
  currentStep: number
  isLoading: boolean
  handleStartEditing: (isEditing?: boolean) => void
  toggleProductHistory: () => void
  toggleProductDiscontinued: () => void
  toggleAgenciesSelect: () => void
  toggleBgFiles: () => void
  toggleEditGeneralInformation: () => void
  handleSaveDraft: () => void
  handleNextStep: () => void
  handlePublishProduct: () => void
  hasIntegratedChannel: boolean
}

export const ActionButtons = ({
  productId,
  status,
  isEditing,
  currentStep,
  isLoading,
  handleStartEditing,
  toggleProductHistory,
  toggleProductDiscontinued,
  toggleAgenciesSelect,
  toggleBgFiles,
  toggleEditGeneralInformation,
  handleSaveDraft,
  handlePublishProduct,
  handleNextStep,
  hasIntegratedChannel,
}: ActionButtonsProps) => {
  const isSystemAdmin = useRolesStore((state) => state.isSystemAdmin)
  const productPermissions = useRolePermissions(ModuleKey.PRODUCT, RoleKey.PRODUCT)
  const commercialPermissions = useRolePermissions(ModuleKey.COMMERCIAL, RoleKey.COMMERCIAL)
  const ecommercePermissions = useRolePermissions(ModuleKey.ECOMMERCE, RoleKey.ECOMMERCE)

  // Charles (fluxo R2)
  const isCreateProductFlow =
    !status ||
    status === 'EM RASCUNHO DO PRODUTO' ||
    status === 'PENDENTE DE ENVIO PARA AGÊNCIA' ||
    status === 'PENDENTE DE LIBERAÇÃO DA AGÊNCIA'

  const isWaitingForProtheus = status === 'PENDENTE DE CADASTRO NO PROTHEUS'

  // Marcio (fluxo R1)
  const isPublishingProduct = status === 'EM RASCUNHO NO E-COMMERCE'

  const isWaitingForPricing = status === 'PENDENTE DE PRECIFICAÇÃO'

  const isPublished = status === 'PUBLICADO'

  const isUnpublished = status === 'DESPUBLICADO'
  const isDiscontinued = status === 'DESCONTINUADO'

  // Produto
  if (
    isSystemAdmin ||
    (productPermissions &&
      (productPermissions.isAdmin || productPermissions.canRead || productPermissions.canUpdate))
  ) {
    if (isCreateProductFlow) {
      return (
        <>
          {status !== 'PENDENTE DE LIBERAÇÃO DA AGÊNCIA' ? (
            <Link to={`/create-product/${productId}`}>
              <S.Button type="default" icon={<EditOutlined />}>
                Editar
              </S.Button>
            </Link>
          ) : (
            <S.Button type="default" icon={<EditOutlined />} onClick={toggleEditGeneralInformation}>
              Editar
            </S.Button>
          )}

          <ViewBgFilesButton toggleBgFiles={toggleBgFiles} />

          <ViewProductHistoryButton toggleHistory={toggleProductHistory} />

          {status === 'PENDENTE DE ENVIO PARA AGÊNCIA' && (
            <S.Button type="primary" icon={<SendOutlined />} onClick={toggleAgenciesSelect}>
              Enviar para Agência
            </S.Button>
          )}
        </>
      )
    }
  }

  // Produto e Ecommerce
  if (
    isSystemAdmin ||
    ((productPermissions || ecommercePermissions) &&
      (productPermissions?.isAdmin ||
        productPermissions?.canRead ||
        ecommercePermissions?.isAdmin ||
        ecommercePermissions?.canRead))
  ) {
    if (isWaitingForProtheus) {
      return (
        <>
          <ViewBgFilesButton toggleBgFiles={toggleBgFiles} />

          <ViewProductHistoryButton toggleHistory={toggleProductHistory} />

          {/* <S.Button type="primary" icon={<AuditOutlined />} disabled>
          Iniciar integração
          </S.Button> */}
        </>
      )
    }
  }

  // Comercial
  if (
    isSystemAdmin ||
    (commercialPermissions && (commercialPermissions.isAdmin || commercialPermissions.canRead))
  ) {
    if (isWaitingForPricing) {
      return (
        <>
          <ViewBgFilesButton toggleBgFiles={toggleBgFiles} />

          <ViewProductHistoryButton toggleHistory={toggleProductHistory} />
        </>
      )
    }
  }

  // Ecommerce
  if (
    isSystemAdmin ||
    (ecommercePermissions && (ecommercePermissions.isAdmin || ecommercePermissions.canRead))
  ) {
    if (isPublishingProduct) {
      return (
        <>
          {!isEditing ? (
            <>
              <ViewBgFilesButton toggleBgFiles={toggleBgFiles} />

              <ViewProductHistoryButton toggleHistory={toggleProductHistory} />

              <S.Button
                type="primary"
                icon={<AuditOutlined />}
                onClick={() => handleStartEditing(false)}
                disabled={!hasIntegratedChannel}
              >
                Iniciar integração
              </S.Button>
            </>
          ) : (
            <>
              {currentStep < 2 && (
                <S.Button
                  type="default"
                  icon={<FormOutlined />}
                  onClick={handleSaveDraft}
                  loading={isLoading}
                >
                  Salvar rascunho <strong>VTEX</strong>
                </S.Button>
              )}

              {currentStep < 2 ? (
                <S.Button
                  type="primary"
                  icon={<ForwardOutlined />}
                  onClick={handleNextStep}
                  loading={isLoading}
                >
                  Continuar
                </S.Button>
              ) : (
                <S.Button
                  type="primary"
                  icon={<CheckOutlined />}
                  onClick={handlePublishProduct}
                  loading={
                    isLoading
                    // || publishVtexMutation.isLoading
                  }
                >
                  Concluir Integração
                </S.Button>
              )}
            </>
          )}
        </>
      )
    }

    if (isPublished || isUnpublished) {
      return (
        <>
          {!isEditing ? (
            <>
              <S.Button
                type="default"
                icon={<EditOutlined />}
                onClick={() => handleStartEditing(true)}
              >
                Editar
              </S.Button>

              <S.Button
                type="default"
                icon={<FileExcelOutlined />}
                onClick={toggleProductDiscontinued}
              >
                Descontinuar produto
              </S.Button>

              <ViewBgFilesButton toggleBgFiles={toggleBgFiles} />

              <ViewProductHistoryButton toggleHistory={toggleProductHistory} />
            </>
          ) : (
            <>
              {currentStep < 2 ? (
                <S.Button
                  type="primary"
                  icon={<ForwardOutlined />}
                  onClick={handleNextStep}
                  loading={isLoading}
                >
                  Continuar
                </S.Button>
              ) : (
                <S.Button
                  type="primary"
                  icon={<CheckOutlined />}
                  onClick={handlePublishProduct}
                  loading={
                    isLoading
                    // || publishVtexMutation.isLoading
                  }
                >
                  Finalizar edição
                </S.Button>
              )}
            </>
          )}
        </>
      )
    }

    // Ecommerce
    if (isDiscontinued) {
      return (
        <>
          <ViewBgFilesButton toggleBgFiles={toggleBgFiles} />

          <ViewProductHistoryButton toggleHistory={toggleProductHistory} />
        </>
      )
    }
  }
}
