import { CheckCircleOutlined } from '@ant-design/icons'
import { Button as AntdButton } from 'antd'
import { styled } from 'styled-components'

// interface CardContentProps {
//   $currentStep: number
// }

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .ant-typography {
    line-height: initial;
  }

  .ant-card .ant-card-body {
    padding: 0;
  }

  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
`

export const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: 500;
  color: #434343;
`

export const PageButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const BackButton = styled(AntdButton)`
  padding: 0;
`

export const Button = styled(AntdButton)`
  display: flex;
  align-items: center;

  .ant-btn-icon {
    font-size: 16px;
  }

  &.ant-btn-default {
    color: ${({ theme }) => theme.colors.brand};
    border-color: ${({ theme }) => theme.colors.brand};
  }

  &.ant-btn-default:disabled {
    background: #fafafa;
    border-color: #d9d9d9;
    color: rgba(65, 66, 67, 0.25);
  }
`

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`
