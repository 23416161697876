import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'
import { Image } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import vtexLogo from '@/assets/logo-vtex-single.svg'
import {
  ApiError,
  formatCurrency,
  getUploadedImageUrl,
  imgFallback,
  toastError,
  truncateString,
} from '@/common'
import { ReturnButton } from '@/components'
import { bgQueries, BgStatusTags, SkuKitItems } from '@/features/BG'
import { KitPageSkeleton } from '@/features/kits'
import { PageLayout } from '@/layouts'

export function KitPage() {
  const navigate = useNavigate()
  const { id: paramProductId } = useParams()

  const {
    data: kitProduct,
    isLoading,
    error,
  } = useQuery(bgQueries.detail(paramProductId as string))

  useEffect(() => {
    if (error) {
      toast.error(
        toastError({
          error: error as ApiError,
          defaultMessage: 'Falha ao carregar dados do produto.',
          errorMessages: new Map([
            ['Product not found', 'Produto não encontrado'],
            ['Not Found', 'Produto não encontrado'],
            ['The given product id is not valid', 'ID de produto inválido'],
          ]),
        }),
      )
    }
  }, [error])

  const ProductCard: React.FC<{ kit: SkuKitItems }> = ({ kit }) => {
    return (
      <S.ItemContainer
        onClick={() => navigate(`/product/${kit.product_id}`, { state: location.pathname })}
      >
        <S.ImageContainer>
          <Image height={64} width={74} preview={false} src={kit?.image} fallback={imgFallback} />
        </S.ImageContainer>

        <S.ProductType>{truncateString(kit?.product_name, 60)}</S.ProductType>
        <S.ProductCategory>{truncateString(kit?.family, 32)}</S.ProductCategory>
        <S.PartNumber>{truncateString(kit?.ref_id, 28)}</S.PartNumber>
        <S.BGName>{truncateString(kit?.model, 72)}</S.BGName>
        {kitProduct?.sku?.stock_quantity_vtex && (
          <S.Stock>
            Estoque VTEX:
            <S.StockValue $isLowStock={kitProduct?.sku?.low_stock_vtex}>
              {kitProduct?.sku?.stock_quantity_vtex}
            </S.StockValue>
          </S.Stock>
        )}
        {kitProduct?.sku?.stock_quantity_anymarket && (
          <S.Stock>
            Estoque Anymarket:
            <S.StockValue $isLowStock={kitProduct?.sku?.low_stock_anymarket}>
              {kitProduct?.sku?.stock_quantity_anymarket}
            </S.StockValue>
          </S.Stock>
        )}
      </S.ItemContainer>
    )
  }

  const ButtonsContainer = () => {
    return (
      <S.PageButtons>
        <ReturnButton />
      </S.PageButtons>
    )
  }

  return (
    <PageLayout title="Kit">
      <S.Container>
        <ButtonsContainer />
        {isLoading ? (
          <KitPageSkeleton />
        ) : (
          <>
            <BgStatusTags status={kitProduct?.status} />

            <S.Row style={{ justifyContent: 'space-between' }}>
              <strong>{kitProduct?.sku?.name}</strong>
              <S.Row>
                <S.ProductPrice $hasMarketplacePrice={!!kitProduct?.sku?.prices.vtex}>
                  <Image
                    src={vtexLogo}
                    width={28}
                    height={24}
                    alt="Logo AnyMarket"
                    preview={false}
                  />
                  <S.Divider type="vertical" />
                  {formatCurrency({
                    value: kitProduct?.sku?.prices.vtex ?? 0,
                    type: 'currency',
                  })}
                </S.ProductPrice>
              </S.Row>
            </S.Row>

            <S.SummaryContainer>
              <S.KitInfoCard>
                <S.Column>
                  <S.FieldLabel>Título</S.FieldLabel>
                  <S.FieldValue>{kitProduct?.title || '--'}</S.FieldValue>
                </S.Column>

                <S.Row>
                  <S.Column style={{ width: '20%' }}>
                    <S.FieldLabel>Palavras Similares</S.FieldLabel>

                    <S.FieldValue>{kitProduct?.key_words || '--'}</S.FieldValue>
                  </S.Column>

                  <S.Divider type="vertical" />

                  <S.Column style={{ flex: 1 }}>
                    <S.FieldLabel>Link</S.FieldLabel>
                    <S.FieldLink
                      href={kitProduct?.link_id}
                      target="_blank"
                      rel="noopener noreferrer external"
                    >
                      {kitProduct?.link_id || '--'}
                    </S.FieldLink>
                  </S.Column>

                  <S.Divider type="vertical" />

                  <S.Column style={{ width: '10%' }}>
                    <S.FieldLabel>Categoria</S.FieldLabel>

                    <S.FieldValue>{kitProduct?.category_name || '--'}</S.FieldValue>
                  </S.Column>

                  <S.Divider type="vertical" />

                  <S.Column style={{ width: '10%' }}>
                    <S.FieldLabel>Part Number</S.FieldLabel>

                    <S.FieldValue>{kitProduct?.ref_id || '--'}</S.FieldValue>
                  </S.Column>
                </S.Row>

                <S.Column>
                  <S.FieldLabel>Dealer Page (URL)</S.FieldLabel>

                  <S.FieldLink
                    href={kitProduct?.partner_files.dealer_page_partner_url}
                    target="_blank"
                    rel="noopener noreferrer external"
                  >
                    {kitProduct?.partner_files.dealer_page_partner_url || '--'}
                  </S.FieldLink>
                </S.Column>

                <S.FreightInfoContainer>
                  <S.FieldLabel>Informações do frete</S.FieldLabel>

                  <S.FreightValues>
                    <S.Column>
                      <S.FieldLabel>Peso</S.FieldLabel>
                      <S.FieldValue>{kitProduct?.sku?.packaged_weight_kg || '--'}kg</S.FieldValue>
                    </S.Column>

                    <S.Divider type="vertical" />

                    <S.Column>
                      <S.FieldLabel>Altura</S.FieldLabel>
                      <S.FieldValue>{kitProduct?.sku?.packaged_height || '--'}cm</S.FieldValue>
                    </S.Column>

                    <S.Divider type="vertical" />

                    <S.Column>
                      <S.FieldLabel>Largura</S.FieldLabel>
                      <S.FieldValue>{kitProduct?.sku?.packaged_width || '--'}cm</S.FieldValue>
                    </S.Column>

                    <S.Divider type="vertical" />

                    <S.Column>
                      <S.FieldLabel>Comprimento</S.FieldLabel>
                      <S.FieldValue>{kitProduct?.sku?.packaged_length || '--'}cm</S.FieldValue>
                    </S.Column>
                  </S.FreightValues>
                </S.FreightInfoContainer>

                <S.Column>
                  <S.FieldLabel>Descrição</S.FieldLabel>

                  <S.DescriptionContainer>
                    <pre>{truncateString(kitProduct?.description || '--', 72)}</pre>
                  </S.DescriptionContainer>
                </S.Column>
              </S.KitInfoCard>
            </S.SummaryContainer>

            <div>
              <S.CardLabel>Itens</S.CardLabel>

              <S.Card>
                <S.ItensContainer>
                  {kitProduct?.sku.sku_kit_itens
                    ? kitProduct?.sku.sku_kit_itens.map((data: SkuKitItems, i: number) => (
                        <ProductCard key={i} kit={data} />
                      ))
                    : []}
                </S.ItensContainer>
              </S.Card>
            </div>

            <div>
              <S.CardLabel>Imagens</S.CardLabel>

              <S.Card>
                <S.ImagesContainer>
                  {kitProduct?.sku.sku_kit_itens?.map((data, i) => (
                    <Image
                      key={i}
                      src={getUploadedImageUrl({ ...data, url: data.image, filename: data.image })}
                      width={124}
                      height={124}
                    />
                  ))}
                </S.ImagesContainer>
              </S.Card>
            </div>
          </>
        )}
      </S.Container>
    </PageLayout>
  )
}
