import { CheckCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.darkGreen};
`

export const PriceColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  color: #6c6c6c;
`

export const PriceRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  padding-right: ${rem(26)};

  .ant-input {
    width: ${rem(148)};
    height: ${rem(32)};
    padding: 0 ${rem(8)};

    text-align: end;
    font-size: ${rem(18)};
    font-weight: 700;
    color: #303030d9;
  }
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #30303099;

  > span {
    padding-top: 2px;
    font-size: 12px;
    font-weight: 700;
  }
`

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`
