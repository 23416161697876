import { useMutation } from '@tanstack/react-query'

import type { CreateProductResponse } from '../../types'

import { api } from '@/services'

export type SaveProductImagePackMutation = {
  images: string[]
  productId: string
  saveAsDraft: boolean
  image_cover_bg: string
}

export const saveProductImagePackErrorMessages = new Map([
  ['Product not found', 'Produto não encontrado'],
  ['Not found', 'Não encontrado'],
  ['The given product id is not valid', 'O id do produto fornecido não é válido'],
  [
    'Product status must be {valid_status.value} to realize this operation',
    'O status do produto deve ser {valid_status.value} para realizar esta operação',
  ],
  ['user-id header is required', 'O cabeçalho usuário é obrigatório'],
  ['Product status is not valid', 'Status do produto não é válido'],
])

export const useSaveProductImagePack = () => {
  return useMutation({
    mutationFn: async ({
      images,
      productId,
      saveAsDraft,
      image_cover_bg,
    }: SaveProductImagePackMutation) => {
      return api.post<CreateProductResponse>(`/skus/sku_files?is_draft=${saveAsDraft}`, {
        images,
        product_id: productId,
        image_cover_bg,
      })
    },
  })
}
2
