import { useMutation, useQueryClient } from '@tanstack/react-query'

import { bgQueries } from '../queries'

import { api } from '@/services'

export const deleteProductErrorMessages = new Map([
  ['Product not found', 'Produto não encontrado'],
  ['The given product id is not valid', 'O id do produto fornecido não é válido'],
])

export const useDeleteProductMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (productId: string) => {
      return api.delete(`/products/${productId}`)
    },
    onMutate: async (productId) => await queryClient.cancelQueries(bgQueries.detail(productId)),
    onSettled: () => queryClient.invalidateQueries({ queryKey: bgQueries.all() }),
  })
}
