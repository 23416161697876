import { Button } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.darkGreen};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: ${rem(24)};
  span {
    color: rgba(48, 48, 48, 0.85);
  }
`
