import { useMemo } from 'react'

import { WarningOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import * as S from './styles'

import { BaseModalProps } from '@/common'
import { BaseModal } from '@/components'
import { BgIntegrationChannels } from '@/features/BG/types'

interface StartIntegrationModalProps extends BaseModalProps {
  handleStartIntegrating: (isEditing?: boolean) => void
  integrationChannels: BgIntegrationChannels
  bgName: string
}

export const StartIntegrationModal = ({
  isOpen,
  onClose,
  handleStartIntegrating,
  integrationChannels,
  bgName,
}: StartIntegrationModalProps) => {
  const isVtexAndCisp1 = useMemo(
    () => integrationChannels.vtex && integrationChannels.cisp1,
    [integrationChannels],
  )

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title={isVtexAndCisp1 ? 'Integração' : 'Confirmação de integração com a VTEX'}
      width={600}
      footer={
        <>
          <S.CancelButton type="link" onClick={onClose} disabled={false}>
            Cancelar
          </S.CancelButton>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => handleStartIntegrating(false)}
            disabled={false}
            loading={false}
          >
            Continuar
          </Button>
        </>
      }
    >
      <S.Container>
        <S.Column>
          <WarningOutlined style={{ fontSize: '36px', color: '#FFA940' }} />
          {isVtexAndCisp1 ? (
            <span>
              Daremos início a <strong>Integração na VTEX</strong> e ao finalizar o processo, será
              solicitado o preço do produto, o qual será <strong>integrado apenas na Cisp1</strong>.
            </span>
          ) : (
            <span>
              Deseja Iniciar as etapas de integração do BG - <strong>{bgName}</strong>, na VTEX?
            </span>
          )}
        </S.Column>
      </S.Container>
    </BaseModal>
  )
}
