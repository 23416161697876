import { useMutation, useQueryClient } from '@tanstack/react-query'

import { bgQueries } from '../../queries'
import type { CreateProductMutation } from './create-product'

import { api } from '@/services'

export interface UpdateProductMutation extends CreateProductMutation {
  productId: string
}

export const updateProductErrorMessages = new Map([
  ['The given product id is not valid', 'O id do produto fornecido não é válido'],
  ['The given sku id is not valid', 'O id do sku fornecido não é válido'],
  ['The product type cannot be changed', 'O tipo do produto não pode ser alterado'],
  ['Product not found', 'Produto não encontrado'],
  ['Product vtex id not found', 'Produto Vtex não encontrado'],
  ['Product not found in ANYMARKET', 'Produto não encontrado no ANYMARKET'],
  ['Product name not found', 'Nome do produto não encontrado'],
  ['Product technical specification not found', 'Especificação técnica do produto não encontrada'],
  ['Category not found', 'Categoria não encontrado'],
  ['Category anymarket id not found', 'Categoria anymarket não encontrada'],
  ['Sku vtex id not found', 'Sku Vtex não encontrado'],
  ['Sku not found', 'Sku não encontrado'],
])

export const useUpdateProductMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ productId, ...data }: UpdateProductMutation) => {
      return api.put(`/products/${productId}/draft`, data)
    },
    onSettled: (_, __, { productId }) => queryClient.invalidateQueries(bgQueries.detail(productId)),
  })
}
