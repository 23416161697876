import { NumericFormat } from 'react-number-format'

import { Button, Form, Image, Input } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import csp1Logo from '@/assets/logo-cisp1.svg'
import { ApiError, BaseModalProps } from '@/common'
import { BaseModal } from '@/components'
import { usePublishCisp1Mutation } from '@/features/BG/mutations'

interface Cisp1IntegrationModalProps extends BaseModalProps {
  productId: string
}

export const Cisp1IntegrationModal = ({
  isOpen,
  onClose,
  productId,
}: Cisp1IntegrationModalProps) => {
  const [form] = Form.useForm()

  const publishCisp1Mutation = usePublishCisp1Mutation()

  async function handleSubmit(values: any) {
    try {
      const formattedPrice = Number(values.price.replace(/\./g, '').replace(',', '.'))

      const promise = publishCisp1Mutation.mutateAsync({
        productId: productId as string,
        price: formattedPrice,
      })

      toast.promise(promise, {
        className: 'toast-sucess',
        loading: 'Publicando no CSP1...',
        success: () => (
          <S.ToastContainer>
            <S.CircleOutlined />

            <div>
              Agência <span style={{ fontWeight: 'bold' }}>criada</span> com{' '}
              <span style={{ fontWeight: 'bold' }}>sucesso</span>!
            </div>
          </S.ToastContainer>
        ),
        error: (error: ApiError) =>
          error.status === 422
            ? 'Houve um erro ao publicar no CSP1'
            : error.status === 502
            ? error.response?.data.detail
            : null,
      })

      await promise
      onClose()
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancel = () => {
    form.resetFields()
    onClose()
  }

  return (
    <BaseModal
      title="Integração Csp1"
      open={isOpen}
      onCancel={onClose}
      width={600}
      footer={
        <>
          <S.CancelButton
            type="link"
            onClick={handleCancel}
            disabled={publishCisp1Mutation.isPending}
          >
            Cancelar
          </S.CancelButton>
          <Button
            type="primary"
            onClick={() => form.submit()}
            disabled={publishCisp1Mutation.isPending}
          >
            Concluir Integração
          </Button>
        </>
      }
    >
      <Form
        form={form}
        layout="horizontal"
        requiredMark="optional"
        autoComplete="off"
        disabled={publishCisp1Mutation.isPending}
        onFinish={handleSubmit}
      >
        <S.PriceColumn>
          <span>
            Definir <strong>preço do produto</strong> em:
          </span>
          <S.PriceRow>
            <Image height={24} width={24} preview={false} src={csp1Logo} />
            <Form.Item
              name="price"
              rules={[{ required: true, message: 'Insira o preço base.' }]}
              label={<S.Label>R$</S.Label>}
            >
              <NumericFormat
                customInput={Input}
                decimalScale={2}
                allowNegative={false}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                onValueChange={(values) => {
                  form.setFieldValue('price', values.floatValue)
                }}
              />
            </Form.Item>
          </S.PriceRow>
        </S.PriceColumn>
      </Form>
    </BaseModal>
  )
}
