import { useMutation, useQueryClient } from '@tanstack/react-query'

import { bgQueries } from '../queries'

import { api } from '@/services'

type EditGeneralInformationMutation = {
  productId: string
  model: string
  family: string
}

export const editGeneralInformationErrorMessages = new Map([
  ['Product not found', 'Produto não encontrado'],
  ['The given product id is not valid', 'O id do produto fornecido não é válido'],
  ['Product with model already exists', 'Produto com modelo já existe'],
])

export const useEditGeneralInformationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ productId, ...data }: EditGeneralInformationMutation) => {
      return api.patch(`/products/${productId}/model_and_family`, data)
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: bgQueries.all() }),
  })
}
