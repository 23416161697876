import { memo } from 'react'

import { Row, Col } from 'antd'

import * as S from './styles'

import { ProductService } from '@/features/BG/types'

interface BgServicesProps {
  service: ProductService
}

const sallesChannelsRecommendedLabel: Record<string, string> = {
  YES: 'Sim',
  NO: 'Não',
}

const BgServiceCard = memo(function BgServiceCard({ service }: BgServicesProps) {
  return (
    <S.Card key={service.name}>
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <S.Card>
            <strong>Nome Comercial</strong>
            {service?.commercial_name || '-'}
          </S.Card>
        </Col>
        <Col span={6}>
          <S.Card>
            <strong>Tipo</strong>
            {service?.service_type || '-'}
          </S.Card>
        </Col>
        <Col span={6}>
          <S.Card>
            <strong>Prazo</strong>
            {service?.term || '-'}
          </S.Card>
        </Col>
        <Col span={24}>
          <S.Card>
            <strong>Benefícios</strong>
            {service?.benefits.length
              ? service?.benefits.map((benefit) => <span key={benefit}>{benefit}</span>)
              : '-'}
          </S.Card>
        </Col>
        <Col span={24}>
          <S.Card>
            <strong>Descrição</strong>
            {service?.description || '-'}
          </S.Card>
        </Col>
        <Col span={12}>
          <S.Card>
            <strong>Condições Gerais</strong>
            <a href={service?.general_condition_url} target="_blank" rel="noreferrer">
              Link
            </a>
          </S.Card>
        </Col>
        <Col span={12}>
          <S.Card>
            <strong>Termo de Contratação</strong>
            <a href={service?.contractual_term_url} target="_blank" rel="noreferrer">
              Link
            </a>
          </S.Card>
        </Col>
        <Col span={24}>
          <S.Card>
            <strong>Canais de Venda</strong>
            <S.ChannelsList>
              {service?.salles_channels.length
                ? service?.salles_channels.map((channel) => (
                    <li key={channel.name}>
                      <span>
                        <strong>Nome:</strong> {channel.name}
                      </span>
                      <span>
                        <strong>Recomendado:</strong>{' '}
                        {sallesChannelsRecommendedLabel[channel.recommended]}
                      </span>
                    </li>
                  ))
                : '-'}
            </S.ChannelsList>
          </S.Card>
        </Col>
      </Row>
    </S.Card>
  )
})

export default BgServiceCard
