import { useMutation } from '@tanstack/react-query'

import { CreateProductKitRequest } from '../types'

import { api } from '@/services'

export const createKitErrorMessages = new Map([
  ['Sku not found or Sku not integrated with Vtex', 'Sku não encontrado ou não integrado com Vtex'],
])

export const createProductKitErrorMessages = new Map([
  ['Product not found', 'Produto não encontrado'],
  ['Category not found', 'Categoria não encontrada'],
  ['user-id header is required', 'O cabeçalho usuário é obrigatório'],
  ['Sku not found or Sku not integrated with Vtex', 'Sku não encontrado ou não integrado com Vtex'],
])

export const useCreateProductKitMutation = () => {
  return useMutation({
    mutationFn: async (data: CreateProductKitRequest) => {
      return api.post('/products/kit', data)
    },
  })
}
