import { ApiError } from '..'

interface ToastError {
  error: ApiError
  errorMessages?: Map<string, string>
  defaultMessage: string
}

export const toastError = ({ error, errorMessages, defaultMessage }: ToastError) => {
  const errorDetail = error.response?.data.detail

  if (errorDetail) {
    return errorMessages?.get(errorDetail) || defaultMessage
  }

  return `${defaultMessage}: ${error.message}`
}
