import { useCallback, useEffect, useState } from 'react'

import { EditOutlined, SaveOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Skeleton, Switch, Tooltip } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { ApiError, formatCurrency, toastError } from '@/common'
import { HelpTooltip } from '@/components'
import { usePatchProductIntegratedChannelsMutation } from '@/features/BG/mutations/create-product/patch-product-integrated-channels'
import { bgQueries } from '@/features/BG/queries'
import { BgIntegrationChannels, ProductStatus, Sku } from '@/features/BG/types'

interface ChannelSelectorProps {
  integrationChannels: BgIntegrationChannels
  setIntegrationChannels: React.Dispatch<React.SetStateAction<BgIntegrationChannels>>
  productId?: string
  refetch?: VoidFunction
  prices?: Sku['prices']
  stock?: {
    [key: string]: {
      stockQuantity: number | undefined
      isLowStock: boolean | undefined
    }
  }
  status?: ProductStatus
}

const statusAfterIntegration = [
  'PENDENTE DE PRECIFICAÇÃO',
  'PUBLICADO',
  'DESPUBLICADO',
  'DESCONTINUADO',
]

export const ChannelSelector = ({
  productId,
  setIntegrationChannels,
  integrationChannels = {},
  refetch = () => null,
  prices,
  stock,
  status,
}: ChannelSelectorProps) => {
  const isUpdate = !!productId
  const [disabled, setDisabled] = useState<boolean>(isUpdate)
  const patchProductIntegratedChannels = usePatchProductIntegratedChannelsMutation()
  const { data: channels, isLoading } = useQuery(bgQueries.integratedChannels())

  async function handleSave() {
    if (integrationChannels.vtex === false && integrationChannels.anymarket === true) {
      toast.error('Para publicar no AnyMarket é necessário que o canal da VTEX esteja ativo')
      return
    }

    if (productId) {
      const promise = patchProductIntegratedChannels.mutateAsync({
        productId,
        ...integrationChannels,
      })

      toast.promise(promise, {
        loading: 'Salvando canais...',
        success: () => {
          setDisabled(true)
          refetch()
          return (
            <S.ToastContainer>
              <S.CircleOutlined />

              <div>
                Canais <span style={{ fontWeight: 'bold' }}>editados</span> com{' '}
                <span style={{ fontWeight: 'bold' }}>sucesso</span>!
              </div>
            </S.ToastContainer>
          )
        },
        className: 'toast-sucess',
        error: (error: ApiError) =>
          toastError({
            error,
            defaultMessage: 'Erro ao editar canais',
          }),
      })
    }
  }

  useEffect(() => {
    if (!Object.keys(integrationChannels).length && channels && !isUpdate) {
      let defaultChannels = {}

      for (const category in channels) {
        channels[category].forEach((channel) => {
          defaultChannels = { ...defaultChannels, [channel]: false }
        })
      }

      setIntegrationChannels(defaultChannels)
    }
  }, [channels, integrationChannels, isUpdate, setIntegrationChannels])

  const renderTable = useCallback(
    (category: string) => {
      const data = channels
        ? channels[category].map((channel) => ({
            checked: integrationChannels[channel],
            channel,
            price: prices ? prices[channel as keyof Sku['prices']] : 0,
            stock: stock ? stock[channel]?.stockQuantity : 0,
            isLowStock: stock ? stock[channel]?.isLowStock : true,
          }))
        : []

      const isMarketPlace = category === 'marketplace'

      return (
        <S.CustomTable
          size="small"
          $hide={!isMarketPlace}
          pagination={false}
          dataSource={data}
          columns={[
            {
              title: (
                <S.Row>
                  <S.CategoryTitle>
                    {isMarketPlace ? 'Habilitar Produto' : 'Outros'}
                    {isMarketPlace ? (
                      <HelpTooltip
                        title={'Produto estará pronto para publicação nos canais ativados'}
                      />
                    ) : null}
                  </S.CategoryTitle>
                </S.Row>
              ),
              key: 'marketplace',
              width: 200,
              render: (row) => (
                <S.ItemContainer>
                  <Switch
                    unCheckedChildren="Não"
                    checkedChildren="Sim"
                    checked={row.checked}
                    disabled={disabled || statusAfterIntegration.includes(status || '')}
                    onChange={(e) =>
                      setIntegrationChannels((prev) => ({ ...prev, [row.channel]: e }))
                    }
                  />
                  <S.ChannelTag $checked={row.checked} $channel={row.channel}>
                    {row.channel}
                  </S.ChannelTag>
                </S.ItemContainer>
              ),
            },
            {
              title: <S.CategoryTitle $hide={!isMarketPlace}>Precificação</S.CategoryTitle>,
              dataIndex: '',
              key: 'price',
              render: (row) => (
                <S.ChannelInfo $disabled={disabled} $color={row.checked ? '#000' : '#D9D9D9'}>
                  {row.price
                    ? formatCurrency({
                        value: row.price,
                        type: 'currency',
                      })
                    : 'R$ 00,00'}
                </S.ChannelInfo>
              ),
            },
            {
              title: <S.CategoryTitle $hide={!isMarketPlace}>Estoque</S.CategoryTitle>,
              dataIndex: '',
              key: 'stock',
              render: (row) => {
                console.log(stock)
                return (
                  <S.ChannelInfo
                    $disabled={disabled}
                    $color={
                      row.checked ? (row.isLowStock > 100 ? '#4A91FD' : '#FF4D4DD4') : '#D9D9D9'
                    }
                  >
                    {row.stock || '00'}
                  </S.ChannelInfo>
                )
              },
            },
          ]}
        />
      )
    },
    [channels, disabled, integrationChannels, prices, setIntegrationChannels, status, stock],
  )

  return (
    <S.Container $disabled={disabled}>
      <S.TitleContainer $disabled={disabled}>
        <h2>Canais e Preços</h2>
        {isUpdate && (
          <Tooltip
            placement="topLeft"
            title={
              <>
                Clique para <strong>{disabled ? 'Editar' : 'Salvar'}</strong> as alterações
              </>
            }
          >
            {disabled ? (
              <EditOutlined size={14} onClick={() => setDisabled(false)} />
            ) : (
              <SaveOutlined onClick={handleSave} />
            )}
          </Tooltip>
        )}
      </S.TitleContainer>

      {isLoading ? (
        <Skeleton active />
      ) : (
        channels &&
        Object.keys(channels).map((category) => (
          <S.CategoryContainer key={category} $disabled={disabled}>
            {renderTable(category)}
          </S.CategoryContainer>
        ))
      )}
    </S.Container>
  )
}
