import { useMutation } from '@tanstack/react-query'

import { api } from '@/services'

export type PatchProductIntegratedChannels = {
  productId: string
}

export const usePatchProductIntegratedChannelsMutation = () => {
  return useMutation({
    mutationFn: async ({ productId, ...data }: PatchProductIntegratedChannels) => {
      return api.patch(`/products/${productId}/integrated_channels`, data)
    },
  })
}
