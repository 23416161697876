import { useMutation, useQueryClient } from '@tanstack/react-query'

import { bgQueries } from '../queries'

import { api } from '@/services'

interface ProductDiscontinuedMutation {
  productId: string
  description: string
}

export const productDiscontinuedErrorMessages = new Map([
  ['Product not found', 'Produto não encontrado'],
  ['SKU not found', 'SKU não encontrado'],
  ['Product already discontinued', 'Produto já descontinuado'],
  ['The given product id is not valid', 'O id do produto fornecido não é válido'],
  [
    'Product status must be "" to realize this operation',
    'O status do produto deve ser "" para realizar esta operação',
  ],
  ['user-id header is required', 'O cabeçalho usuário é obrigatório'],
])

export const useProductDiscontinuedMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ productId, description }: ProductDiscontinuedMutation) => {
      return api.patch(`/products/${productId}/discontinue`, {
        description: description,
      })
    },
    onSettled: (_, __, { productId }) => queryClient.invalidateQueries(bgQueries.detail(productId)),
  })
}
